import Route from "@/routes";
import { useAppSelector } from "@redux/hook";
import { authState } from "@redux/store";
import Spinner from "./components/Spinner";
import { useChangeLanguage, useInitSessionInfo } from "./hooks";

const App = () => {
  const { isInitialized } = useAppSelector(authState);

  useInitSessionInfo();

  useChangeLanguage();

  if (!isInitialized) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner size="lg" />
      </div>
    );
  }

  return <Route />;
};

export default App;
