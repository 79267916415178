import { PERMISSION } from "@/constants/global";
import { ROUTE } from "@/constants/route";
import { useHasPermissions } from "@/hooks";
import { Navigate } from "@cinnamon/design-system";
import { FunctionComponent, memo, PropsWithChildren, ReactElement } from "react";

export interface AuthRouteProps {
  permissions?: PERMISSION[];
  element?: ReactElement;
}

const AuthRoute: FunctionComponent<PropsWithChildren<AuthRouteProps>> = (props) => {
  const { element, children, permissions } = props;
  const hasPermissions = useHasPermissions();

  if (!hasPermissions(permissions)) {
    return <Navigate to={ROUTE.NO_PERMISSION.to} />;
  }

  return <>{element || children}</>;
};

export default memo(AuthRoute);
