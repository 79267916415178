import { API_URL } from "@/constants/config";
import { APIListQuery, APIListResponse } from "@/types/global";
import { Group, GroupAssignUser, GroupCreate, GroupId, GroupUpdate } from "@/types/group";
import { api } from "./api";

export const groupApi = api.injectEndpoints({
  endpoints: (build) => ({
    getGroups: build.query<APIListResponse<Group>, APIListQuery>({
      query: (params) => ({
        url: API_URL.GROUPS,
        method: "GET",
        params,
      }),
      providesTags: () => [{ type: "Groups", id: "LIST" }],
    }),
    getGroup: build.query<Group, GroupId>({
      query: (id) => ({
        url: `${API_URL.GROUPS}${id}/`,
        method: "GET",
      }),
      providesTags: (_result, _error, id) => [{ type: "Groups", id }],
    }),
    createGroup: build.mutation<Group, GroupCreate>({
      query: (data) => ({
        url: API_URL.GROUPS,
        method: "POST",
        data,
      }),
      invalidatesTags: [{ type: "Groups", id: "LIST" }],
    }),
    deleteGroup: build.mutation<void, GroupId>({
      query: (id) => ({
        url: `${API_URL.GROUPS}${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: "Groups", id: "LIST" },
        { type: "Groups", id },
        { type: "Users", id: "SEARCH_LIST" }, // invalidTag user list -> update current user to unassigned group
      ],
    }),
    updateGroup: build.mutation<Group, GroupUpdate>({
      query: ({ id, name }) => ({
        url: `${API_URL.GROUPS}${id}/`,
        method: "PUT",
        data: { name },
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "Groups", id: "LIST" },
        { type: "Groups", id },
      ],
    }),
    assignUserToGroup: build.mutation<Group, GroupAssignUser>({
      query: ({ id, userId }) => ({
        url: `${API_URL.GROUPS}${id}/assign/`,
        method: "POST",
        data: { userId },
      }),
      invalidatesTags: (_result, _error, { id, userId }) => [
        { type: "Groups", id: "LIST" },
        { type: "Users", id: "SEARCH_LIST" },
        { type: "Groups", id },
        { type: "Users", id: userId },
      ],
    }),
  }),
});

export const {
  useGetGroupQuery,
  useGetGroupsQuery,
  useLazyGetGroupsQuery,
  useDeleteGroupMutation,
  useUpdateGroupMutation,
  useCreateGroupMutation,
  useAssignUserToGroupMutation,
} = groupApi;
