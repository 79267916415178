import { APIListResponse, HTTP_STATUS_CODE } from "@/types/global";
import { toCamelCase, toSnakeCase } from "@/utils";
import { API_PATH, payloadToFormData, REFRESH_TOKEN_STORAGE_NAME, TOKEN_STORAGE_NAME } from "@cinnamon/design-system";
import { retry, type BaseQueryFn } from "@reduxjs/toolkit/query/react";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import axios, { isAxiosError } from "axios";

export interface AxiosBaseQueryParams {
  baseURL: string;
  maxRetries?: number;
  disableReAuth?: boolean;
  refreshUrl?: string;
  loginUrl?: string;
  logout?: VoidFunction;
}

export interface BaseQueryArgs extends AxiosRequestConfig {
  emptyListResponse?: boolean;
}

export interface BaseQueryResult {}

export interface BaseQueryError {
  status?: number;
  data?: unknown;
}

export interface BaseQueryExtraOptions {
  disableParseResponse?: boolean;
  disableParseErrorResponse?: boolean;
  disableParseRequest?: boolean;
}

export interface BaseQueryMeta extends AxiosResponse {}

const axiosInstance = axios.create();

const setupAxiosInstance = (params: AxiosBaseQueryParams) => {
  const { baseURL, disableReAuth, refreshUrl = API_PATH.REFRESH, loginUrl = API_PATH.LOGIN, logout } = params;

  axiosInstance.defaults.baseURL = baseURL;

  axiosInstance.interceptors.request.use((config) => {
    const authToken = localStorage.getItem(TOKEN_STORAGE_NAME);
    if (config.url !== loginUrl && config.headers && authToken) {
      config.headers.Authorization = authToken;
    }
    config.headers["Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    config.headers["Timezone-Offset"] = new Date().getTimezoneOffset();
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (isAxiosError(error)) {
        return Promise.reject(error);
      }

      if (
        isAxiosError(error) ||
        (disableReAuth && originalRequest.url === loginUrl && error.response.status === HTTP_STATUS_CODE.UNAUTHORIZED)
      ) {
        return Promise.reject(error);
      }

      if (
        (disableReAuth || originalRequest.url === refreshUrl) &&
        error.response.status === HTTP_STATUS_CODE.UNAUTHORIZED
      ) {
        logout?.();
        localStorage.clear();
        window.location.reload();
        return Promise.reject(error);
      }

      const isRefreshToken =
        ![loginUrl, refreshUrl].includes(originalRequest.url) &&
        error.response.status === HTTP_STATUS_CODE.UNAUTHORIZED &&
        !originalRequest._retry;

      if (isRefreshToken) {
        originalRequest._retry = true;
        const refresh = `${localStorage.getItem(REFRESH_TOKEN_STORAGE_NAME)}`;
        const {
          data: { access: token },
        } = await axiosInstance({
          method: "post",
          url: refreshUrl,
          data: payloadToFormData({ refresh }),
        });

        const accessToken = `Bearer ${token}`;
        localStorage.setItem(TOKEN_STORAGE_NAME, accessToken);

        return axiosInstance(originalRequest);
      }

      if (
        error.request.responseType === "blob" &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf("json") != -1
      ) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (typeof reader.result === "string") {
              error.response.data = JSON.parse(reader.result);
            }
            resolve(Promise.reject(error));
          };

          reader.onerror = () => {
            reject(error);
          };

          reader.readAsText(error.response.data);
        });
      }

      return Promise.reject(error);
    }
  );
};

export const axiosBaseQuery = (
  params: AxiosBaseQueryParams
): BaseQueryFn<BaseQueryArgs, BaseQueryResult, BaseQueryError, BaseQueryExtraOptions, BaseQueryMeta> => {
  setupAxiosInstance(params);

  return async (args, api, extraOptions) => {
    try {
      if (args.emptyListResponse) {
        const data: APIListResponse<unknown> = {
          total: 0,
          items: [],
        };

        return {
          data,
        };
      }

      const result = await axiosInstance({
        ...args,
        params: extraOptions?.disableParseRequest ? args.params : toSnakeCase(args.params),
        data: extraOptions?.disableParseRequest ? args.data : toSnakeCase(args.data),
        signal: api.signal,
      });

      return {
        data: extraOptions?.disableParseResponse ? result.data : toCamelCase(result.data),
        meta: result,
      };
    } catch (err) {
      if (!isAxiosError(err)) {
        return {
          error: {
            data: err,
          },
        };
      }
      const errorData = err.response?.data || { code: err?.code, message: err?.message };

      return {
        error: {
          status: err.response?.status,
          data: errorData,
        },
      };
    }
  };
};

export const axiosBaseQueryWithRetry = (params: AxiosBaseQueryParams) => {
  const { maxRetries = 1, ...otherParams } = params;

  return retry(axiosBaseQuery(otherParams), { maxRetries });
};
