import { API_URL } from "@/constants/config";
import { DownloadId } from "@/types/download";
import { api } from "./api";
import { BaseQueryMeta } from "./axiosBaseQuery";

export const downloadApi = api.injectEndpoints({
  endpoints: (build) => ({
    download: build.mutation<BaseQueryMeta, DownloadId>({
      query: (id) => ({
        url: `${API_URL.DOWNLOADS}${id}/`,
        method: "GET",
        responseType: "blob",
        shouldGetFullResponse: true,
      }),
      extraOptions: {
        disableParseResponse: true,
      },
      transformResponse: (_, meta: BaseQueryMeta) => meta,
    }),
  }),
});

export const { useDownloadMutation } = downloadApi;
