import { PERMISSION } from "@/constants/global";
import { USER_ROLE } from "./user";

export interface APIListQuery {
  sort?: string;
  skip?: number;
  limit?: number;
  page?: number;
  size?: number;
}

export interface APIListResponse<T> {
  total: number;
  items: T[];
}

export interface RouteSchema {
  to: string;
  path: string;
  permissions?: PERMISSION[];
  darkModeRoles?: USER_ROLE[];
}

export enum HTTP_STATUS_CODE {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  UNPROCESSABLE_ENTITY = 422,
  INTERNAL_SERVER_ERROR = 500,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  NETWORK_ERROR = 600,
  UNKNOWN_ERROR = 700,
}

type CamelCase<S extends string> = S extends `${infer P1}_${infer P2}${infer P3}`
  ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
  : Lowercase<S>;

type ObjectToCamel<T> = {
  [K in keyof T as CamelCase<string & K>]: T[K] extends Record<string, unknown> ? KeysToCamelCase<T[K]> : T[K];
};

export type KeysToCamelCase<T> = {
  [K in keyof T as CamelCase<string & K>]: T[K] extends Array<unknown>
    ? KeysToCamelCase<T[K][number]>[]
    : ObjectToCamel<T[K]>;
};

type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? "_" : ""}${Lowercase<T>}${CamelToSnakeCase<U>}`
  : S;

export type KeysToSnakeCase<T> = {
  [K in keyof T as CamelToSnakeCase<string & K>]: T[K];
};

export interface ListState {
  total: number | null;
  skip: number;
}

export interface ErrorResponse {
  status: HTTP_STATUS_CODE;
  data: {
    code: string;
    message: string;
  };
}

export interface ErrorUserLoginResponse {
  access_token: string;
  is_2fa_enabled: boolean;
  is_2fa_verified: boolean;
  token_type: string;
}

export interface Error2FAResponse {
  status: HTTP_STATUS_CODE;
  data: {
    access_token: string;
    is_2fa_enabled: boolean;
    is_2fa_verified: boolean;
    token_type: string;
  };
}

export type ErrorLoginResponse = ErrorResponse | Error2FAResponse;
