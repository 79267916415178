import { PERMISSION } from "@/constants/global";
import { authApi } from "@/services/auth";
import { User } from "@/types/user";
import { TOKEN_STORAGE_NAME } from "@cinnamon/design-system";
import { createSlice } from "@reduxjs/toolkit";
import jwt from "jwt-decode";

export interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  permissions: PERMISSION[];
}

const initialState: AuthState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  permissions: [],
};

interface DecodedToken {
  scopes?: PERMISSION[];
}

const getPermissionsUserFromStorage = (): PERMISSION[] => {
  const token = localStorage.getItem(TOKEN_STORAGE_NAME);
  const decodedToken = jwt<DecodedToken>(token || "");
  return decodedToken?.scopes || [];
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initSession: (state) => {
      state.isInitialized = true;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.permissions = [];

      localStorage.removeItem(TOKEN_STORAGE_NAME);
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, (_, action) => {
        localStorage.setItem(TOKEN_STORAGE_NAME, `${action.payload.tokenType} ${action.payload.accessToken}`);
      })
      .addMatcher(authApi.endpoints.getCurrentUserInfo.matchFulfilled, (state, action) => {
        const permissions = getPermissionsUserFromStorage();

        state.isInitialized = true;
        state.isAuthenticated = true;
        state.user = action.payload;
        state.permissions = permissions;
      })
      .addMatcher(authApi.endpoints.verifyOtp.matchFulfilled, (_, action) => {
        localStorage.setItem(TOKEN_STORAGE_NAME, `${action.payload.tokenType} ${action.payload.accessToken}`);
      })
      .addMatcher(authApi.endpoints.getCurrentUserInfo.matchRejected, (state) => {
        state.isInitialized = true;
        localStorage.removeItem(TOKEN_STORAGE_NAME);
      });
  },
});

export const { initSession, logout } = slice.actions;

export default slice.reducer;
