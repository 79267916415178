import { useAppSelector } from "@/redux/hook";
import { USER_ROLE } from "@/types/user";
import { useCallback } from "react";

export const useHasRoles = () => {
  const user = useAppSelector((state) => state.auth.user);

  return useCallback(
    (roles?: USER_ROLE[]) => {
      if (!user || !roles) {
        return false;
      }

      return roles.includes(user.role);
    },
    [user]
  );
};
