import { PERMISSION } from "@/constants/global";
import { useAppSelector } from "@/redux/hook";
import { useCallback } from "react";

export const useHasPermissions = () => {
  const authPermissions = useAppSelector((state) => state.auth.permissions);

  return useCallback(
    (permissions?: PERMISSION[]) => {
      if (!permissions) {
        return true;
      }

      return authPermissions.some((authPermission) => permissions.includes(authPermission));
    },
    [authPermissions]
  );
};
