import LoadingScreen from "@/components/LoadingScreen";
import { LOGIN_SEARCH_PARAMS, ROUTE } from "@/constants/route";
import { useHasRoles } from "@/hooks";
import { useAppSelector } from "@/redux/hook";
import { authState } from "@/redux/store";
import {
  CinNoPermissionPage,
  CinNotFoundPage,
  Navigate,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  matchPath,
  useLocation,
  useSearchParams,
} from "@cinnamon/design-system";
import React, { FC, ReactNode, useEffect, useMemo } from "react";
import AuthRoute from "./AuthRoute";

const LoginPage = React.lazy(() => import("@/pages/Login"));

const ChatPage = React.lazy(() => import("@/pages/Chat"));
const DocumentPage = React.lazy(() => import("@/pages/Document"));
const UserPage = React.lazy(() => import("@/pages/User"));
const CompanySettingsPage = React.lazy(() => import("@/pages/CompanySettings"));
const AccountPage = React.lazy(() => import("@/pages/Account"));

const AuthLayout = React.lazy(() => import("@/layouts/AuthLayout"));
const UnAuthLayout = React.lazy(() => import("@/layouts/UnAuthLayout"));

interface RouteType {
  name?: string;
  children?: ReactNode;
}

interface ConfigRoute {
  isAuth?: boolean;
}

interface UnAuthRoute extends ConfigRoute {
  redirectTo: string | null;
}

const getUnAuthRoutes = ({ redirectTo, isAuth }: UnAuthRoute) => ({
  path: ROUTE.LOGIN.to,
  element: !isAuth ? <UnAuthLayout /> : <Navigate to={redirectTo || ROUTE.HOME.to} />,
  children: [{ path: "", element: <LoginPage /> }],
});

interface AuthRoute extends ConfigRoute {
  prevPathName: string;
  prevSearch: string;
}

const getAuthRoutes = ({ isAuth, prevPathName, prevSearch }: AuthRoute) => ({
  path: ROUTE.HOME.to,
  element: isAuth ? (
    <AuthLayout />
  ) : (
    <Navigate to={`${ROUTE.LOGIN.to}?${LOGIN_SEARCH_PARAMS.REDIRECT_TO}=${prevPathName}${prevSearch}`} />
  ),
  children: [
    {
      path: "*",
      element: <Navigate to={ROUTE.NOT_FOUND.to} />,
    },
    {
      path: "",
      element: (
        <Navigate
          to={ROUTE.CHAT.to}
          replace
        />
      ),
    },
    { path: ROUTE.CHAT.path, element: <ChatPage /> },
    { path: ROUTE.CHAT_CONVERSATION.path, element: <ChatPage /> },
    { path: ROUTE.DOCUMENT.path, element: <DocumentPage /> },
    { path: ROUTE.DOCUMENT_GROUP.path, element: <DocumentPage /> },
    { path: ROUTE.DOCUMENT_GROUP_FOLDER.path, element: <DocumentPage /> },
    { path: ROUTE.USER.path, element: <UserPage />, permissions: ROUTE.USER.permissions },
    { path: ROUTE.USER_GROUP.path, element: <UserPage />, permissions: ROUTE.USER_GROUP.permissions },
    { path: ROUTE.USER_GROUP_MEMBER.path, element: <UserPage />, permissions: ROUTE.USER_GROUP_MEMBER.permissions },
    {
      path: ROUTE.COMPANY_SETTINGS.path,
      element: <CompanySettingsPage />,
      permissions: ROUTE.COMPANY_SETTINGS.permissions,
    },
    { path: ROUTE.ACCOUNT.path, element: <AccountPage /> },
  ],
});

const getOtherRoutes = () => [
  { path: ROUTE.NO_PERMISSION.path, element: <CinNoPermissionPage /> },
  { path: ROUTE.NOT_FOUND.path, element: <CinNotFoundPage /> },
];

const Root: FC = () => {
  const { user, isInitialized, isAuthenticated } = useAppSelector(authState);
  const { pathname: prevPathName, search: prevSearch } = useLocation();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get(LOGIN_SEARCH_PARAMS.REDIRECT_TO);
  const isAuth = isAuthenticated && user;
  const hasRoles = useHasRoles();

  const unAuthRoutes = useMemo(() => getUnAuthRoutes({ redirectTo, isAuth: !!isAuth }), [isAuth, redirectTo]);
  const authRoutes = useMemo(
    () => getAuthRoutes({ isAuth: !!isAuth, prevPathName, prevSearch }),
    [isAuth, prevPathName, prevSearch]
  );
  const otherRoutes = getOtherRoutes();

  useEffect(() => {
    document.documentElement.classList.toggle(
      "dark",
      Object.values(ROUTE).some((route) => matchPath(route.path, prevPathName) && hasRoles(route.darkModeRoles))
    );
  }, [hasRoles, prevPathName]);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return (
    <Routes>
      {/* unAuthRoute */}
      <Route
        path={unAuthRoutes.path}
        element={unAuthRoutes.element}>
        {unAuthRoutes.children.map((route) => (
          <Route
            key={`${unAuthRoutes.path}-${route.path}`}
            path={route.path}
            element={route.element}
          />
        ))}
      </Route>

      {/* authRoute */}
      <Route
        path={authRoutes.path}
        element={authRoutes.element}>
        {authRoutes.children.map((route) => (
          <Route
            key={`${authRoutes.path}-${route.path}`}
            path={route.path}
            element={
              <AuthRoute
                element={route.element}
                permissions={route.permissions}
              />
            }
          />
        ))}
      </Route>

      {/* other Auth */}
      {otherRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={route.element}
        />
      ))}
    </Routes>
  );
};

const RouteConfig: FC<RouteType> = (): JSX.Element => {
  const router = createBrowserRouter([
    {
      path: "*",
      element: <Root />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default RouteConfig;
