import { useAppDispatch } from "@/redux/hook";
import { initSession } from "@/redux/reducer/authSlice";
import { useLazyGetCurrentUserInfoQuery } from "@/services/auth";
import { TOKEN_STORAGE_NAME, useMounting } from "@cinnamon/design-system";

export const useInitSessionInfo = () => {
  const [getCurrentUserInfo] = useLazyGetCurrentUserInfoQuery();
  const dispatch = useAppDispatch();

  useMounting(() => {
    const accessToken = localStorage.getItem(TOKEN_STORAGE_NAME);

    if (accessToken) {
      getCurrentUserInfo().unwrap();
    } else {
      dispatch(initSession());
    }
  });
};
