import type { RouteSchema } from "@/types/global";
import { USER_ROLE } from "@/types/user";
import { PERMISSION } from "./global";

export enum ROUTE_KEY {
  HOME = "HOME",
  LOGIN = "LOGIN",
  LOGIN_VERIFY = "LOGIN_VERIFY",
  CHAT = "CHAT",
  CHAT_CONVERSATION = "CHAT_CONVERSATION",
  DOCUMENT = "DOCUMENT",
  DOCUMENT_GROUP = "DOCUMENT_GROUP",
  DOCUMENT_GROUP_FOLDER = "DOCUMENT_GROUP_FOLDER",
  USER = "USER",
  USER_GROUP = "USER_GROUP",
  USER_GROUP_MEMBER = "USER_GROUP_MEMBER",
  COMPANY_SETTINGS = "COMPANY_SETTINGS",
  ACCOUNT = "ACCOUNT",
  NO_PERMISSION = "NO_PERMISSION",
  NOT_FOUND = "NOT_FOUND",
}

export const ROUTE: Record<ROUTE_KEY, RouteSchema> = {
  HOME: {
    to: "/",
    path: "",
  },
  LOGIN: {
    to: "/login",
    path: "login",
  },
  LOGIN_VERIFY: {
    to: "/login/verify",
    path: "verify",
  },
  CHAT: {
    to: "/chat",
    path: "chat",
  },
  CHAT_CONVERSATION: {
    to: "/chat",
    path: "chat/:conversationId",
  },
  DOCUMENT: {
    to: "/document",
    path: "document",
    darkModeRoles: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.GROUP_ADMIN],
  },
  DOCUMENT_GROUP: {
    to: "/document",
    path: "document/:groupId",
    darkModeRoles: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.GROUP_ADMIN],
  },
  DOCUMENT_GROUP_FOLDER: {
    to: "/document",
    path: "document/:groupId/:folderId",
    darkModeRoles: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.GROUP_ADMIN],
  },
  USER: {
    to: "/user",
    path: "user",
    permissions: [PERMISSION.USER_UPDATE],
    darkModeRoles: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.GROUP_ADMIN],
  },
  USER_GROUP: {
    to: "/user",
    path: "user/:groupId",
    permissions: [PERMISSION.USER_UPDATE],
    darkModeRoles: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.GROUP_ADMIN],
  },
  USER_GROUP_MEMBER: {
    to: "/user",
    path: "user/:groupId/:userId",
    permissions: [PERMISSION.USER_UPDATE],
    darkModeRoles: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.GROUP_ADMIN],
  },
  COMPANY_SETTINGS: {
    to: "/company-settings",
    path: "company-settings",
    permissions: [PERMISSION.CONFIG_UPDATE],
    darkModeRoles: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.GROUP_ADMIN],
  },
  ACCOUNT: {
    to: "/account",
    path: "account",
    darkModeRoles: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.GROUP_ADMIN],
  },
  NO_PERMISSION: {
    to: "/401",
    path: "401",
  },
  NOT_FOUND: {
    to: "/404",
    path: "404",
  },
};

export const LOGIN_SEARCH_PARAMS = {
  REDIRECT_TO: "redirectTo",
};
