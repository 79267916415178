import { API_URL } from "@/constants/config";
import { REQUEST_HEADER_FORM_DATA_TYPE } from "@/constants/global";
import { Attachment, AttachmentId } from "@/types/attachment";
import { downloadFromBinary, getFileNameFromDisposition } from "@cinnamon/design-system";
import { api } from "./api";

export const attachmentApi = api.injectEndpoints({
  endpoints: (build) => ({
    uploadAttachment: build.mutation<Attachment, File>({
      query: (file) => {
        const formData = new FormData();
        formData.append("file", file);

        return {
          url: API_URL.ATTACHMENTS,
          method: "POST",
          headers: REQUEST_HEADER_FORM_DATA_TYPE,
          data: formData,
        };
      },
      extraOptions: {
        disableParseRequest: true,
      },
    }),
    downloadAttachment: build.mutation<void, AttachmentId>({
      query: (id) => ({
        url: `${API_URL.ATTACHMENTS}${id}/download/`,
        method: "GET",
        responseType: "blob",
        shouldGetFullResponse: true,
      }),
      extraOptions: {
        disableParseResponse: true,
      },
      transformResponse: (_, meta) => {
        const fileName = getFileNameFromDisposition(meta?.headers["content-disposition"]);
        downloadFromBinary(meta?.data, fileName);
      },
    }),
  }),
});

export const { useUploadAttachmentMutation, useDownloadAttachmentMutation } = attachmentApi;
