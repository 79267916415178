import { useAppSelector } from "@/redux/hook";
import { USER_LANGUAGE } from "@/types/user";
import i18n from "@/utils/i18n";
import { LANGUAGE } from "@cinnamon/design-system";
import { useEffect } from "react";

export const useChangeLanguage = () => {
  const { user, isInitialized } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (!isInitialized) {
      return;
    }

    if (user?.language === USER_LANGUAGE.ENGLISH) {
      i18n.changeLanguage(LANGUAGE.ENGLISH);
      return;
    }

    if (user?.language === USER_LANGUAGE.JAPANESE) {
      i18n.changeLanguage(LANGUAGE.JAPANESE);
      return;
    }

    i18n.changeLanguage(/^ja\b/.test(navigator.language) ? LANGUAGE.JAPANESE : LANGUAGE.ENGLISH);
  }, [isInitialized, user?.language]);
};
