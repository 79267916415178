import { camelCase, cloneDeep, isArray, isPlainObject, map, mapKeys, mapValues } from "lodash";

export const toCamelCase = <TResponse = unknown>(object: unknown): TResponse => {
  if (!object) {
    return object as TResponse;
  }

  let camelCaseObject = cloneDeep(object);

  if (isArray(camelCaseObject)) {
    if (camelCaseObject.every((item) => typeof item === "string")) {
      return camelCaseObject as TResponse;
    }
    return map(camelCaseObject, toCamelCase) as TResponse;
  } else {
    camelCaseObject = mapKeys(camelCaseObject as object, (_, key) => {
      return camelCase(key);
    });

    // Recursively apply throughout object
    return mapValues(camelCaseObject as object, (value) => {
      if (isPlainObject(value)) {
        return toCamelCase(value);
      } else if (isArray(value)) {
        if ((value as unknown[]).every((item) => typeof item === "string")) {
          return value as TResponse;
        }
        return map(value, toCamelCase);
      } else {
        return value;
      }
    }) as TResponse;
  }
};
