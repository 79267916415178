import { cloneDeep, isArray, isPlainObject, map, mapKeys, mapValues, snakeCase } from "lodash";

export const toSnakeCase = <TResponse = unknown>(object: unknown): TResponse => {
  if (!object) {
    return object as TResponse;
  }

  let snakeCaseObject = cloneDeep(object);

  if (isArray(snakeCaseObject)) {
    if (snakeCaseObject.every((item) => typeof item === "string")) {
      return snakeCaseObject as TResponse;
    }
    return map(snakeCaseObject, toSnakeCase) as TResponse;
  } else {
    snakeCaseObject = mapKeys(snakeCaseObject as object, (_, key) => {
      return snakeCase(key);
    });

    // Recursively apply throughout object
    return mapValues(snakeCaseObject as object, (value) => {
      if (isPlainObject(value)) {
        return toSnakeCase(value);
      } else if (isArray(value)) {
        if ((value as unknown[]).every((item) => typeof item === "string")) {
          return value as TResponse;
        }
        return map(value, toSnakeCase);
      } else {
        return value;
      }
    }) as TResponse;
  }
};
