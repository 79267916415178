import { DO_NOT_ASK_2FA_AGAIN_KEY, REQUEST_HEADER_FORM_TYPE } from "@/constants/global";
import { VerifyOTP } from "@/types/auth";
import { AccessToken, Auth2FA, User, UserLogin, UserLoginResponse } from "@/types/user";
import { APP_CONFIG, AUTH_API_URL } from "@constants/config";
import { api } from "./api";

interface RefreshPayload {
  refresh: string;
}

export interface ForgetPasswordPayload {
  email: string;
}

export interface ResetPasswordPayload {
  token: string;
  password: string;
}

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<UserLoginResponse, UserLogin>({
      query: (data) => {
        const _data = data;
        const skip2fa = localStorage.getItem(DO_NOT_ASK_2FA_AGAIN_KEY) === data.username;

        if (skip2fa) {
          _data["skip_2fa"] = true;
        }

        return {
          headers: REQUEST_HEADER_FORM_TYPE,
          url: AUTH_API_URL.LOGIN,
          method: "POST",
          data,
        };
      },
      extraOptions: {
        disableParseRequest: true,
        retryCondition: () => false,
      },
    }),
    getCurrentUserInfo: build.query<User, void>({
      query: () => ({
        method: "POST",
        url: AUTH_API_URL.USER_INFO,
      }),
    }),
    refreshToken: build.mutation<User, RefreshPayload>({
      query: (data) => ({
        method: "POST",
        url: AUTH_API_URL.REFRESH,
        data,
      }),
    }),
    forgetPassword: build.mutation<void, ForgetPasswordPayload>({
      query: (data) => ({
        method: "POST",
        url: AUTH_API_URL.FORGOT_PASSWORD,
        data,
      }),
    }),
    resetPassword: build.mutation<void, ResetPasswordPayload>({
      query: (params) => ({
        method: "POST",
        url: AUTH_API_URL.RESET_PASSWORD,
        params,
      }),
    }),
    checkResetPasswordToken: build.mutation<void, string>({
      query: (token) => ({
        method: "GET",
        url: AUTH_API_URL.RESET_PASSWORD,
        params: { token },
      }),
    }),
    issueOTP: build.mutation<void, void>({
      query: () => ({
        method: "POST",
        url: AUTH_API_URL.ISSUE_OTP,
      }),
    }),
    verifyOtp: build.mutation<VerifyOTP, VerifyOTP>({
      query: ({ accessToken, otp, tokenType }) => ({
        headers: {
          Authorization: `${tokenType} ${accessToken}`,
          ...REQUEST_HEADER_FORM_TYPE,
        },
        method: "POST",
        url: `${APP_CONFIG.AUTH_API_ROOT}/verify-otp/`,
        data: { otp },
      }),
      transformErrorResponse: (response, _meta, arg) => {
        return { ...response, ...arg };
      },
    }),
    toggle2FASetting: build.mutation<Auth2FA, boolean>({
      query: (enable) => {
        const url = enable ? `${APP_CONFIG.AUTH_API_ROOT}/enable-2fa/` : `${APP_CONFIG.AUTH_API_ROOT}/disable-2fa/`;

        return {
          method: "POST",
          url,
        };
      },
    }),
    resendOtp: build.mutation<void, AccessToken>({
      query: ({ accessToken, tokenType }) => ({
        headers: {
          Authorization: `${tokenType} ${accessToken}`,
        },
        method: "POST",
        url: `${APP_CONFIG.AUTH_API_ROOT}/otp/`,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLazyGetCurrentUserInfoQuery,
  useRefreshTokenMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
  useCheckResetPasswordTokenMutation,
  useIssueOTPMutation,
  useVerifyOtpMutation,
  useToggle2FASettingMutation,
  useResendOtpMutation,
} = authApi;
