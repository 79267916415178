import "@/utils/i18n.ts";
import { CinDesignSystemProvider, CinErrorBoundary, cinTheme } from "@cinnamon/design-system";
import store from "@redux/store";
import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App.tsx";
import "./index.css";

window.addEventListener("vite:preloadError", (event) => {
  window.location.reload(); // for example, refresh the page
  event.preventDefault();
});

const container = document.getElementById("root");

const root = createRoot(container!);

root.render(
  <StrictMode>
    <Suspense fallback={<div className="flex h-screen dark:bg-custom-black3" />}>
      <Provider store={store}>
        <CinDesignSystemProvider theme={cinTheme}>
          <CinErrorBoundary>
            <App />
          </CinErrorBoundary>
        </CinDesignSystemProvider>
      </Provider>
    </Suspense>
  </StrictMode>
);
