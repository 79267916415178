import { FunctionComponent, memo } from "react";
import Spinner from "../Spinner";

interface LoadingScreenProps {}

const LoadingScreen: FunctionComponent<LoadingScreenProps> = () => {
  return (
    <div className="flex h-screen items-center justify-center dark:bg-custom-black3">
      <Spinner size="lg" />
    </div>
  );
};

export default memo(LoadingScreen);
