import classNames from "classnames";
import { FC, memo } from "react";
import styles from "./style.module.scss";

export interface SpinnerProps {
  className?: string;
  size?: "sm" | "md" | "lg";
}

const Spinner: FC<SpinnerProps> = ({ className, size = "md", ...props }) => (
  <div
    className={classNames(styles.container, styles[size], className)}
    {...props}
  />
);

export default memo(Spinner);
