import { api } from "@/services/api";
import { rootReducer } from "@redux/reducer/rootReducer";
import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";

const middleware = [api.middleware];

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
    }).concat(middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const authState = (state: RootState) => state.auth;
export const modeState = (state: RootState) => state.mode;

export default store;
