import { USER_LANGUAGE, USER_ROLE } from "@/types/user";

export const TRANSITION_DURATION = 300;

export const DARK_MODE_STORAGE_KEY = "DARK_MODE";

export const DO_NOT_ASK_2FA_AGAIN_KEY = "do_not_ask_2fa_again";

export const REQUEST_HEADER_FORM_TYPE = {
  "Content-Type": "application/x-www-form-urlencoded",
};

export const REQUEST_HEADER_FORM_DATA_TYPE = {
  "Content-Type": "multipart/form-data",
};

export enum FILE_CATEGORY {
  ATTACHMENTS = "ATTACHMENTS",
  REFERENCES = "REFERENCES",
}

export const MAX_LENGTH_EMAIL = 320;

export enum PERMISSION {
  ATTACHMENT_CREATE = "attachment:create",
  ATTACHMENT_DELETE = "attachment:delete",
  ATTACHMENT_DOWNLOAD = "attachment:download",
  ATTACHMENT_READ = "attachment:read",
  ATTACHMENT_UPDATE = "attachment:update",
  CHAT_CREATE = "chat:create",
  CHAT_DELETE = "chat:delete",
  CHAT_READ = "chat:read",
  CHAT_UPDATE = "chat:update",
  COLLECTION_CREATE = "collection:create",
  COLLECTION_DELETE = "collection:delete",
  COLLECTION_DOWNLOAD = "collection:download",
  COLLECTION_READ = "collection:read",
  COLLECTION_UPDATE = "collection:update",
  CONFIG_CREATE = "config:create",
  CONFIG_DELETE = "config:delete",
  CONFIG_READ = "config:read",
  CONFIG_UPDATE = "config:update",
  DOWNLOAD_CREATE = "download:create",
  DOWNLOAD_DELETE = "download:delete",
  DOWNLOAD_READ = "download:read",
  DOWNLOAD_UPDATE = "download:update",
  GROUP_ADD_USER = "group:adduser",
  GROUP_CREATE = "group:create",
  GROUP_DELETE = "group:delete",
  GROUP_READ = "group:read",
  GROUP_REMOVE_USER = "group:removeuser",
  GROUP_UPDATE = "group:update",
  MESSAGE_CREATE = "message:create",
  MESSAGE_DELETE = "message:delete",
  MESSAGE_READ = "message:read",
  MESSAGE_UPDATE = "message:update",
  PERMISSION_CREATE = "permission:create",
  PERMISSION_DELETE = "permission:delete",
  PERMISSION_READ = "permission:read",
  PERMISSION_UPDATE = "permission:update",
  REFERENCE_CREATE = "reference:create",
  REFERENCE_DELETE = "reference:delete",
  REFERENCE_DOWNLOAD = "reference:download",
  REFERENCE_READ = "reference:read",
  REFERENCE_UPDATE = "reference:update",
  USER_CREATE = "user:create",
  USER_CREATE_ADMIN = "user:createadmin",
  USER_CREATE_SYSTEM_ADMIN = "user:createsystemadmin",
  USER_DELETE = "user:delete",
  USER_DELETE_ADMIN = "user:deleteadmin",
  USER_DELETE_SYSTEM_ADMIN = "user:deletesystemadmin",
  USER_READ = "user:read",
  USER_UPDATE = "user:update",
  USER_UPDATE_ADMIN = "user:updateadmin",
  USER_UPDATE_SYSTEM_ADMIN = "user:updatesystemadmin",
}

export const USER_ROLE_TEXT: Record<USER_ROLE, string> = {
  [USER_ROLE.SYSTEM_ADMIN]: "SYSTEM ADMIN",
  [USER_ROLE.GROUP_ADMIN]: "GROUP ADMIN",
  [USER_ROLE.USER]: "",
};

export const ROLE_TEXT: Record<USER_ROLE, string> = {
  [USER_ROLE.SYSTEM_ADMIN]: "COMMON.ROLE_SYSTEM_ADMIN",
  [USER_ROLE.GROUP_ADMIN]: "COMMON.ROLE_GROUP_ADMIN",
  [USER_ROLE.USER]: "COMMON.ROLE_GENERAL_USER",
};

export const USER_LANGUAGE_TEXT: Record<USER_LANGUAGE, string> = {
  [USER_LANGUAGE.AUTO_DETECT]: "LANGUAGE.AUTO_DETECT",
  [USER_LANGUAGE.ENGLISH]: "LANGUAGE.ENGLISH",
  [USER_LANGUAGE.JAPANESE]: "LANGUAGE.JAPANESE",
};

export const PASSWORD_PLACEHOLDER = "･････････････････";
