import { APP_CONFIG, AUTH_API_URL } from "@/constants/config";
import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQueryWithRetry } from "./axiosBaseQuery";

export const api = createApi({
  reducerPath: "api",
  baseQuery: axiosBaseQueryWithRetry({
    baseURL: APP_CONFIG.API_ROOT,
    loginUrl: AUTH_API_URL.LOGIN,
    disableReAuth: true,
  }),
  tagTypes: ["Conversations", "Users", "Groups", "Folders", "Attachments", "References", "Config"],
  endpoints: () => ({}),
});
