import { api } from "@/services/api";
import authReducer from "@redux/reducer/authSlice";
import modeReducer from "@redux/reducer/modeSlice";
import { combineReducers } from "@reduxjs/toolkit";

export const rootReducer = combineReducers({
  auth: authReducer,
  mode: modeReducer,
  [api.reducerPath]: api.reducer,
});
