import { DARK_MODE_STORAGE_KEY } from "@/constants/global";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  darkMode: JSON.parse(localStorage.getItem(DARK_MODE_STORAGE_KEY) || "false") as boolean,
};

const modeSlice = createSlice({
  name: "mode",
  initialState: initialState,
  reducers: {
    setMode(state, value) {
      state.darkMode = value.payload;
      localStorage.setItem(DARK_MODE_STORAGE_KEY, JSON.stringify(state.darkMode));
    },
  },
});

export const { setMode } = modeSlice.actions;

export default modeSlice.reducer;
