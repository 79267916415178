import { API_URL } from "@/constants/config";
import { Download } from "@/types/download";
import { APIListResponse } from "@/types/global";
import { Reference, ReferenceId, ReferenceIds, SearchReferencesAPIListQuery } from "@/types/reference";
import { downloadFromBinary, getFileNameFromDisposition } from "@cinnamon/design-system";
import { api } from "./api";

export const referenceApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchReferences: build.query<APIListResponse<Reference>, SearchReferencesAPIListQuery>({
      query: ({ emptyListResponse, ...params }) => ({
        url: `${API_URL.REFERENCES}search/`,
        method: "GET",
        params,
        emptyListResponse,
      }),
      providesTags: () => [{ type: "References", id: "SEARCH_LIST" }],
    }),
    downloadReferences: build.mutation<Download, ReferenceIds>({
      query: ({ referenceIds }) => ({
        url: `${API_URL.REFERENCES}download/`,
        method: "POST",
        data: { referenceIds },
      }),
    }),
    downloadReference: build.mutation<void, ReferenceId>({
      query: (id) => ({
        url: `${API_URL.REFERENCES}${id}/download/`,
        method: "GET",
        responseType: "blob",
        shouldGetFullResponse: true,
      }),
      extraOptions: {
        disableParseResponse: true,
      },
      transformResponse: (_, meta) => {
        const fileName = getFileNameFromDisposition(meta?.headers["content-disposition"]);
        downloadFromBinary(meta?.data, fileName);
      },
    }),
  }),
});

export const { useDownloadReferenceMutation, useDownloadReferencesMutation, useLazySearchReferencesQuery } =
  referenceApi;
